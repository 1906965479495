<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
    rounded="sm"
    spinner-variant="primary"
  >
    <b-card>
      <b-card-header
        class="pb-1 mb-1 border-bottom justify-content-start p-0"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          @click="$router.back()"
        >
          <feather-icon
            class="mr-25"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <h3
          v-if="exerciseDetails"
          class="mb-0 ml-2"
        >
          Students for
          {{ exerciseDetails.length > 0 ? exerciseDetails[0].programs_assignmenttables[0].title : 'Default Title' }}
        </h3>
      </b-card-header>
      <div class="d-flex filters-box mb-2">
        <div class="fl-col-1">
          <span>Filters</span>
        </div>
        <div class="fl-col-2 d-flex justify-content-start">
          <b-navbar-nav class="nav align-items-start mr-50">
            <b-nav-item-dropdown
              class="dropdown-user nomarker"
              left
              toggle-class="d-flex align-items-center dropdown-user-link"
            >
              <template #button-content>
                <b-button
                  :variant="filters[0].selected ? 'primary' : 'outline-primary'"
                  class="d-flex align-items-center py-50 px-1"
                  pill
                  @click="filters[0].open=!filters[0].open"
                >
                  <span>{{ filters[0].title }}</span>
                  <div
                    v-if="filters[0].selected != null"
                    :class="`${filters[0].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[0].selected.length ? filters[0].selected.length : '1' }}
                  </div>
                  <div
                    v-else
                    class="text-white py-25"
                    style="border-radius:50px; font-weight: bolder;"
                  >.
                  </div>
                  <feather-icon
                    :icon="filters[0].open?'ChevronUpIcon':'ChevronDownIcon'"
                    class="ml-50"
                  />
                </b-button>
              </template>

              <div
                class="custom-search px-1 pt-1"
                style="cursor: pointer;"
                @click="filters[0].selected=null"
              >
                <h6 class="text-primary">
                  -- No Filter --
                </h6>
              </div>
              <div
                class="custom-search px-1 pt-50"
              >
                <b-form-group
                  class="mb-0"
                >
                  <b-form-checkbox
                    :checked="filters[0].selected && filters[0].selected === 'pf'"
                    @change="selectFilter(0, 'Single', 'pf')"
                  >
                    Partially Filled
                  </b-form-checkbox>
                </b-form-group>
              </div>
              <div
                class="custom-search px-1 pt-50"
              >
                <b-form-group
                  class="mb-0"
                >
                  <b-form-checkbox
                    :checked="filters[0].selected && filters[0].selected === 'ff'"
                    @change="selectFilter(0, 'Single', 'ff')"
                  >
                    Fully Filled
                  </b-form-checkbox>
                </b-form-group>
              </div>
              <div
                class="custom-search px-1 pt-50"
              >
                <b-form-group
                  class="mb-0"
                >
                  <b-form-checkbox
                    :checked="filters[0].selected && filters[0].selected === 'nf'"
                    @change="selectFilter(0, 'Single', 'nf')"
                  >
                    Not Filled
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav class="nav align-items-start mr-50">
            <b-nav-item-dropdown
              class="dropdown-user nomarker"
              left
              toggle-class="d-flex align-items-center dropdown-user-link"
            >
              <template #button-content>
                <b-button
                  :variant="filters[1].selected ? 'primary' : 'outline-primary'"
                  class="d-flex align-items-center py-50 px-1"
                  pill
                  @click="filters[1].open=!filters[1].open"
                >
                  <span>{{ filters[1].title }}</span>
                  <div
                    v-if="filters[1].selected != null"
                    :class="`${filters[1].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[1].selected.length ? filters[1].selected.length : '1' }}
                  </div>
                  <div
                    v-else
                    class="text-white py-25"
                    style="border-radius:50px; font-weight: bolder;"
                  >.
                  </div>
                  <feather-icon
                    :icon="filters[1].open?'ChevronUpIcon':'ChevronDownIcon'"
                    class="ml-50"
                  />
                </b-button>
              </template>

              <div
                class="custom-search px-1 pt-1"
                style="cursor: pointer;"
                @click="filters[1].selected=null"
              >
                <h6 class="text-primary">
                  -- No Filter --
                </h6>
              </div>
              <div
                v-for="mentor in mentors"
                :key="mentor.id"
                class="custom-search px-1 pt-50"
              >
                <b-form-group
                  class="mb-0"
                >
                  <b-form-checkbox
                    :checked="filters[1].selected && filters[1].selected.includes(mentor.id)"
                    @change="selectFilter(1, 'Multiple', mentor.id)"
                  >
                    {{ mentor.users_customuser.full_name }}
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav class="nav align-items-start mr-50">
            <div style="padding: 0.358rem 0;">
              <b-button
                :variant="filters[2].selected ? 'primary' : 'outline-primary'"
                class="d-flex align-items-center py-50 px-1"
                pill
                @click="fetchSortScore()"
              >
                <span>{{ filters[2].title }}</span>
                <div
                  :class="`${filters[2].selected ? 'bg-white text-primary px-50' : 'text-primary'} ml-50 py-25`"
                  style="border-radius:50px; font-weight: bolder;"
                >
                  <feather-icon
                    :icon="filters[2].selected?'XIcon':'CheckIcon'"
                  />
                </div>
              </b-button>
            </div>
          </b-navbar-nav>
          <b-navbar-nav class="nav align-items-start mr-50">
            <div style="padding: 0.358rem 0;">
              <b-button
                :variant="filters[3].selected ? 'primary' : 'outline-primary'"
                class="d-flex align-items-center py-50 px-1"
                pill
                @click="fetchSortVerdict()"
              >
                <span>{{ filters[3].title }}</span>
                <div
                  :class="`${filters[3].selected ? 'bg-white text-primary px-50' : 'text-primary'} ml-50 py-25`"
                  style="border-radius:50px; font-weight: bolder;"
                >
                  <feather-icon
                    :icon="filters[3].selected?'XIcon':'CheckIcon'"
                  />
                </div>
              </b-button>
            </div>
          </b-navbar-nav>
          <b-navbar-nav
            v-if="filters[2].selected && filters[3].selected"
            class="nav align-items-start mr-50"
          >
            <div style="padding: 0.358rem 0;">
              <b-button
                class="d-flex align-items-center py-50 px-1"
                variant="flat-primary"
              >
                <span>{{ filters[4].title }}</span>
                <div class="d-flex align-items-center">
                  <b-form-select
                    v-model="filters[4].selected"
                    :options="[10,20,30,40,50,60,70,80,90,100]"
                    class="px-0 py-0 pl-50 text-primary"
                    style="background: none; box-shadow: none; border: none; height: auto;"
                  />
                  %
                </div>
              </b-button>
            </div>
          </b-navbar-nav>
          <!-- <b-navbar-nav class="nav align-items-start mr-50">
            <b-nav-item-dropdown
              class="dropdown-user nomarker"
              left
              toggle-class="d-flex align-items-center dropdown-user-link"
            >
              <template #button-content>
                <b-button
                  :variant="filters[5].selected ? 'primary' : 'outline-primary'"
                  class="d-flex align-items-center py-50 px-1"
                  pill
                  @click="filters[5].open = !filters[5].open"
                >
                  <span>{{ filters[5].title }}</span>
                  <div
                    v-if="filters[5].selected !== null"
                    :class="`${filters[5].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[5].selected.length ? filters[5].selected.length : '1' }}
                  </div>
                  <div
                    v-else
                    class="text-white py-25"
                    style="border-radius:50px; font-weight: bolder;"
                  >.
                  </div>
                  <feather-icon
                    :icon="filters[5].open?'ChevronUpIcon':'ChevronDownIcon'"
                    class="ml-50"
                  />
                </b-button>
              </template>

              <div
                class="custom-search px-1 pt-1"
                style="cursor: pointer;"
                @click="filters[5].selected=null"
              >
                <h6 class="text-primary">
                  -- No Filter --
                </h6>
              </div>
              <div
                class="custom-search px-1 pt-50"
              >
                <b-form-group
                  class="mb-0"
                >
                  <b-form-checkbox
                    :checked="filters[5].selected && filters[5].selected === 1"
                    @change="selectFilter(5, 'Single', 1)"
                  >
                    Yes
                  </b-form-checkbox>
                </b-form-group>
              </div>
              <div
                class="custom-search px-1 pt-50"
              >
                <b-form-group
                  class="mb-0"
                >
                  <b-form-checkbox
                    :checked="filters[5].selected && filters[5].selected === -1"
                    @change="selectFilter(5, 'Single', -1)"
                  >
                    No
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </b-nav-item-dropdown>
          </b-navbar-nav> -->
          <!-- <div class="filter-holder">
            </div> -->
        </div>
        <!-- <div class="fl-col-3">
            <b-button
              @click="openAdvanceModal()"
              variant="primary"
              size="sm"
            >
              Advance
            </b-button>
          </div> -->
      </div>
      <vue-good-table
        :columns="startupColumns"
        :pagination-options="{
          enabled: true,
          perPage: startupPageLength
        }"
        :rows="filteredRows"
        :search-options="{
          enabled: true,
          externalQuery: startupSearchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Status -->
          <span v-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-button
                :to="{name: 'review-questions',
                      params: {pid:$route.params.program_id,
                               apid: $route.params.assignment_id,
                               aid: props.row.id,},
                      query: {readonly: 'true'}}"
                class="mr-50"
                size="sm"
                variant="outline-primary"
              > View Exercise </b-button>
              <b-button
                :to="{name: 'review-evaluations',params: {pid:$route.params.program_id,
                                                          apid: $route.params.assignment_id,
                                                          aid: props.row.id,}}"
                size="sm"
                variant="outline-primary"
              >View Evaluations</b-button>
            </span>
          </span>
          <!-- Column: Action -->
          <span
            v-else-if="props.column.field === 'title'"
            class="d-flex justify-content-between"
          >
            <span>
              {{ props.row.title }}
            </span>
            <span>
              <!--              <b-badge-->
              <!--                v-if="filters[3].selected"-->
              <!--                class="mr-50"-->
              <!--                variant="light-primary"-->
              <!--              >-->
              <!--                {{-->
              <!--                  getVerdictName(parseFloat(props.row.programs_scoretables_aggregate.aggregate.avg.computed_verdict)-->
              <!--                    .toFixed(1))-->
              <!--                }}-->
              <!--              </b-badge>-->
              <!-- <b-badge
                v-if="filters[2].selected"
                class="mr-50"
                variant="primary"
              >
                {{
                  props.row.programs_scoretables_aggregate.aggregate.avg.participant_score !== 0.0 ? parseFloat(props.row.programs_scoretables_aggregate.aggregate.avg.participant_score)
                    .toFixed(1) : 'Unscored'
                }}
              </b-badge>
              <b-badge
                v-if="props.row.programs_startupresponsetables_aggregate.aggregate.count !== 0"
                class="mx-50"
                variant="light-primary"
              >
                {{ props.row.programs_startupresponsetables_aggregate.aggregate.count > 0 ? 'Partially Filled' : '' }}
              </b-badge> -->
            </span>
            <!-- <feather-icon
                fill="currentColor"
                :class="props.row.programs_assignmentquestionstables[0].programs_startupresponsetables_aggregate.aggregate.count?'text-success':'text-danger'"
                icon="CircleIcon"
              /> -->
          </span>
          <span
            v-else-if="props.column.label === 'Verdict'"
            class="d-flex justify-content-between align-items-center"
          >
            {{ props.formattedRow[props.column.field] || '-' }}
            <b-button
              size="sm"
              variant="flat-primary"
              @click="openRecommedationModal(props.row.id)"
            >Update</b-button>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] || '-' }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                align="right"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <b-modal
      id="recommendationModal"
      ok-only
      ok-title="Update"
      title="Recommendations"
      @ok="updateStatus"
    >
      <div>
        <b-form-group
          label="Recommendations"
        >
          <b-form-input
            v-model="review.recommendation"
            placeholder="Your recommendation for startup"
          />
        </b-form-group>
        <b-form-group
          label="Verdict"
        >
          <v-select
            v-model="review.verdict"
            :options="verdict"
            placeholder="Select from list"
          />
        </b-form-group>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BNavbarNav,
  BNavItemDropdown,
  BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import gql from 'graphql-tag'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormInput,
    // FormBuilder,
    BBadge,
    // BRow,
    // BCol,
    // BFormInput,
    BCard,
    BFormGroup,
    BFormCheckbox,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    vSelect,
    // Treeselect,
    BNavItemDropdown,
    BNavbarNav,
  },
  data() {
    return { // Define your columns here
      startupRows: [], // Define your rows here
      startupPageLength: 10,
      studentResponse: [],
      startupSearchTerm: '',
      mentors: [],
      exerciseDetails: [],
      verdict: ['Excellent', 'Good', 'Satisfactory', 'Unsatisfactory', 'Incomplete'],
      startupColumns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Recommendation',
          field: 'recommendation',
          formatter: value => {
            if (!value || value.length === 0) {
              return '-'
            }
            return value
          },
        },
        {
          label: 'Verdict',
          field: 'verdict',
          formatter: value => {
            if (!value || value.length === 0) {
              return '-'
            }
            return value
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      filters: [
        {
          open: false,
          selected: null,
          title: 'Type',
        },
        {
          open: false,
          selected: null,
          title: 'Mentors',
        },
        {
          open: false,
          selected: null,
          title: 'Sort By Score',
        },
        {
          open: false,
          selected: false,
          title: 'Sort By Verdict',
        },
        {
          open: false,
          selected: 50,
          title: 'Weightage of Verdict :',
        },
        {
          open: false,
          selected: null,
          title: 'Is Assigned',
        },
      ],
      review: {
        id: '',
        score_id: '',
        verdict: '',
        recommendation: '',
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        enabled: 'light-primary',
        disabled: 'light-danger',
        draft: 'light-secondary',
        null: 'light-secondary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filteredRows() {
      let filteredRows = [...this.startupRows]
      // Filter:Type
      if (this.filters[0].selected === 'nf') {
        filteredRows = filteredRows.filter(row => row.reponseCount === 0)
      } else if (this.filters[0].selected === 'pf') {
        filteredRows = filteredRows.filter(row => row.reponseCount > 0 && row.reponseCount < this.studentResponse.length)
      } else if (this.filters[0].selected === 'ff') filteredRows = filteredRows.filter(row => row.reponseCount === this.studentResponse.length)

      // Filter: By Selected Mentors
      if (this.filters[1].selected && this.filters[1].selected.length > 0) {
        const selectedMentorIds = this.filters[1].selected

        filteredRows = filteredRows.filter(row => this.studentResponse.some(response => response.programs_startupresponsetables.some(res => res.participant_id === row.id && selectedMentorIds.includes(res.programs_assignmentdirectscores.partner_id))))
      }

      // Sort: By Score
      if (this.filters[2].selected === true) {
        filteredRows = filteredRows.sort((row1, row2) => {
          const score1 = this.getScoreForParticipant(row1.id)
          const score2 = this.getScoreForParticipant(row2.id)
          return score2 - score1
        })
      }
      // Filter: Sort by Verdict
      if (this.filters[3].selected === true) {
        filteredRows = filteredRows.sort((row1, row2) => {
          const index1 = this.verdict.indexOf(row1.verdict)
          const index2 = this.verdict.indexOf(row2.verdict)

          return index1 - index2
        })
      }
      return filteredRows
    },
  },
  methods: {
    fetchSortScore() {
      this.filters[2].selected = !this.filters[2].selected
    },
    fetchSortVerdict() {
      this.filters[3].selected = !this.filters[3].selected
    },
    selectFilter(index, mode, value) {
      if (mode === 'Single') {
        if (this.filters[index].selected === value) {
          this.filters[index].selected = null
        } else {
          this.filters[index].selected = value
        }
      } else if (mode === 'Multiple') {
        if (this.filters[index].selected !== null && this.filters[index].selected.indexOf(value) >= 0) {
          const temp = this.filters[index].selected
          temp.splice(temp.indexOf(value), 1)
          this.filters[index].selected = temp
          if (temp.length === 0) {
            this.filters[index].selected = null
          }
        } else {
          const temp = this.filters[index].selected ? this.filters[index].selected : []
          temp.push(value)
          this.filters[index].selected = temp
        }
      }
    },
    //  Method to Extract Score for Participant
    getScoreForParticipant(participantId) {
      let score = 0

      this.studentResponse.some(res => {
        if (res.participant_id === participantId) {
          score = res.programs_assignmentdirectscores?.score || 0
          return true
        }
        return false
      })
      return score
    },
    getVerdictName(val) {
      if (val >= 8.5 && val <= 10) {
        return 'Excellent'
      }
      if (val >= 7 && val < 8.5) {
        return 'Good'
      }
      if (val >= 5 && val < 7) {
        return 'Satisfactory'
      }
      return 'Unsatisfactory'
    },
    updateStatus() {
      this.mutationLoading = true
      const mutationObject = {
        id: this.review?.score_id,
        participant_id: this.review?.id,
        verdict: this.review?.verdict,
        recommendation: this.review?.recommendation,
        assignment_id: this.$route.params.assignment_id,
      }

      this.$apollo.mutate(
        {
          mutation: gql`mutation updateStatus($object: programs_assignmentscorerecord_insert_input!) {
              insert_programs_assignmentscorerecord_one(object: $object, on_conflict: {constraint: programs_assignmentscorerecord_pkey, update_columns: [verdict, recommendation]}) {
                id
                verdict
                assignment_id
                recommendation
              }
            }`,
          variables: {
            object: mutationObject,
          },
          update: () => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Updated successfully',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.mutationLoading = false
            this.$apollo.queries.startupRows.refetch()
          },
        },
      )
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'There was an error',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    getMentorsList() {
      let string = ''
      if (this.filters[1].selected) {
        this.filters[1].selected.map(mentor => {
          if (string === '') {
            string = `"${mentor}"`
          } else {
            string = `${string}, "${mentor}"`
          }
          return 0
        })
      }
      return string
    },
    // method to filter the startuprows on the basis of count of response studentresponse

    openRecommedationModal(participantId) {
      // Find entry with participantId in startupRows by id
      const participant = this.startupRows.find(row => row.id === participantId)
      // Copy the entry to review
      this.review = { ...participant }
      this.$bvModal.show('recommendationModal')
    },
  },

  apollo: {
    startupRows: {
      query() {
        return gql`
           query getStudents($program_id: Int!, $assignment_id: Int!) {
          programs_startupparticipants(where: {program_id: {_eq: $program_id}}) {
            id
            programs_applicantstable {
              users_associationtable {
                users_customuser {
                  full_name
                }
              }
            }
            programs_assignmentscorerecords(where: {assignment_id: {_eq: $assignment_id}}) {
                  id
                  verdict
                  recommendation
                }
          }
          programs_assignmentquestionstable(where: {programs_assignmenttable: {id: {_eq: $assignment_id}}}) {
            programs_startupresponsetables {
              participant_id
              programs_assignmentdirectscores {
        partner_id
        score
      }
     }
     }
        }`
      },
      variables() {
        return {
          program_id: this.$route.params.program_id,
          assignment_id: this.$route.params.assignment_id,
        }
      },

      update(data) {
        this.studentResponse = data.programs_assignmentquestionstable
        // crate a flag to filter participants who have partially filled the assignment or fully filled the assignment

        return data.programs_startupparticipants.map(participant => {
          const participantRow = {
            id: participant.id,
            title: participant.programs_applicantstable?.users_associationtable?.users_customuser?.full_name || '',
            reponseCount: 0,
          }

          participantRow.responseCount = data.programs_assignmentquestionstable.filter(question => question.programs_startupresponsetables.some(response => response.participant_id === participant.id)).length

          if (participant.programs_assignmentscorerecords?.length) {
            participantRow.recommendation = participant.programs_assignmentscorerecords[0].recommendation
            participantRow.verdict = participant.programs_assignmentscorerecords[0].verdict
            participantRow.score_id = participant.programs_assignmentscorerecords[0].id
          }
          return participantRow
        })
      },

    },
    mentors: {
      query() {
        return gql`
        query getMentors($assignment_id: Int!) {
          programs_partnerstable(
            where: {
                programs_assignmentdirectscores: {
                    programs_startupresponsetable: {
                        programs_assignmentquestionstable: {
                            assignment_id: {_eq: $assignment_id}
                            }
                           }
                          }}) {
            id
            users_customuser {
              full_name
            }
          }
        }`
      },
      variables() {
        return {
          assignment_id: this.$route.params.assignment_id,
        }
      },
      update: data => data.programs_partnerstable,
    },
    exerciseDetails: {
      query() {
        return gql`
        query exerciseDetails {
          programs_operationstable( where: {program_id: {_eq: ${this.$route.params.program_id}}, programs_assignmenttables: {id: {_eq: ${this.$route.params.assignment_id}}}}) {
            title
            id
            programs_assignmenttables(where: {id: {_eq: ${this.$route.params.assignment_id} }}) {
              id
              title
            }
          }
        }`
      },
      update: data => data.programs_operationstable,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.filters-box {
  border-top: solid 1px #d3d3d3;
  border-bottom: solid 1px #d3d3d3;
}

.fl-col-1 {
  width: fit-content;
  padding-right: 1rem;
  display: flex;
  align-items: center;
}

.fl-col-2 {
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-left: solid 1px #d3d3d3;
  border-right: solid 1px #d3d3d3;
  display: flex;
  flex-wrap: wrap;
  /* overflow-x: auto;
  overflow-y: visible; */
}

.fl-col-2::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.fl-col-2::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fl-col-2::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.filter-holder {
  display: flex;
}

.fl-col-3 {
  width: fit-content;
  padding-left: 1rem;
  display: flex;
  align-items: center;
}
</style>
